import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import Particles, { IParticlesProps } from 'react-tsparticles'
import { Container } from 'tsparticles'

import { LogoView as _LogoView, MainLayout, theme } from '../../shared'
import { starsParticlesOptions, starsParticlesOptions2 } from '../../home'
import { AutomaSectionContainer } from '../../pixel-automa'

export const HomePage = () => {
  const particlesContainerRef = useRef<Container>()

  const particlesInit: IParticlesProps['init'] = async (engine) => {}
  const particlesLoaded: IParticlesProps['loaded'] = async (container) => {
    particlesContainerRef.current = container
  }

  return (
    <MainLayout>
      <BG />
      <Particles
        id='particles'
        init={particlesInit}
        loaded={particlesLoaded}
        options={starsParticlesOptions}
      />
      <View>
        <Hero>
          <LogoView />
        </Hero>
        {/* <Atmosphere /> */}
        {/* <Main>
          <AutomaSectionContainer />
        </Main> */}
      </View>
    </MainLayout>
  )
}

const BG = styled.div({
  backgroundAttachment: 'fixed',
  position: 'fixed',
  zIndex: -2,
  background: [
    theme.colors.background.space.primary,
    `linear-gradient(195deg, ${theme.colors.background.space.primary} 55%, ${theme.colors.background.space.secondary} 100%)`
  ],
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
})

const View = styled.div({})

const Hero = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
})

const LogoView = styled(_LogoView)({
  // marginTop: '15vh',
})

const gradientColorsString = theme.colors.background.atmosphere.map((color, i) => {
  return `${color} ${(i / theme.colors.background.atmosphere.length) * 100}%`
}).join(', ')

const Atmosphere = styled.div({
  background: [
    'transparent',
    `linear-gradient(180deg, ${gradientColorsString})`,
  ],
  minHeight: '70vh',
})

const Main = styled.div({
  // background: theme.colors.background.atmosphere[theme.colors.background.atmosphere.length - 1],
  padding: '0 50px',
})
