export const theme = {
  colors: {
    brand: {
      primary: 'hsl(30, 90%, 70%)',
      primaryTweak: 'hsl(35, 96%, 76%)',
    },
    background: {
      space: {
        primary: 'hsl(249, 90%, 1%)',
        secondary: 'hsl(222, 52%, 25%)',
      },
      atmosphere: [
        'transparent',
        'rgba(27, 43, 148, 0.5)',
        'rgba(141, 109, 171, 0.6)',
        'rgba(202, 129, 152, 0.7)',
        'rgba(255, 160, 128, 0.8)',
        'hsl(225, 85%, 95%)',
      ],
      overlay: 'rgba(0, 0, 0, 0.2)',
    },
    text: {
      primary: '#fefefe',
    },
    layout: {
      border: '#dfdfdf',
      shadow: 'rgba(0, 0, 0, 0.2)',
      shadowLess: 'rgba(0, 0, 0, 0.1)',
    },
  },
  layout: {
    borderRadius: 10,
  },
}
