import React from 'react'
import { Global, css } from '@emotion/react'

import { theme } from '../../shared/theme/theme'

import '/node_modules/modern-normalize/modern-normalize.css'
import '../../shared/fonts/kopitha/stylesheet.css'

export const RootLayout: React.FC = ({ children }) => (
  <>
    <Global styles={globalCSS} />
    {children}
  </>
)

const globalCSS = css({
  html: {
    height: '100%',
    background: theme.colors.background.space.primary,
  },

  body: {
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    color: theme.colors.text.primary,
  },

  h1: {
    fontFamily: 'Kopitha',
    fontSize: '4em',
    marginBlock: 0,
    margin: 0,
    padding: 0,
    paddingBlockEnd: '0.4em',
  }
})
