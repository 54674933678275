import React from 'react'
import styled from '@emotion/styled'

import { RootLayout } from '../../root'

export const MainLayout: React.FC = ({ children }) => (
  <RootLayout>
    <View>
      {children}
    </View>
  </RootLayout>
)

const View = styled.div({
  maxWidth: 1400,
  margin: '0 auto',
})
