import React from 'react'
import styled from '@emotion/styled'

import { theme } from '../theme'

interface InputProps {
  value?: string
  className?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export const Input: React.FC<InputProps> = ({
  value,
  className,
  onChange,
}) => (
  <View
    value={value}
    className={className}
    onChange={onChange}
  />
)

const View = styled.input({
  background: theme.colors.background.overlay,
  border: `1px solid ${theme.colors.background.space.secondary}`,
  padding: '8px 18px',
  minHeight: 40,
  color: theme.colors.text.primary,
  borderRadius: 500,
  outline: 0,

  ['&:active']: {
    border: `1px solid ${theme.colors.background.atmosphere[1]}`,
  },
})
