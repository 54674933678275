import React from 'react'
import styled from '@emotion/styled'

import { theme } from '../theme/theme'

export interface LogoViewProps {
  className?: string
}

export const LogoView: React.FC<LogoViewProps> = ({ className }) => (
  <View className={className}>
      <LogoInner>
        <LogoText>
          <LogoTextLine>Ryan</LogoTextLine>
          <LogoTextLine>Wiesjahn</LogoTextLine>
        </LogoText>
      </LogoInner>
  </View>
)

const View = styled.div({
  border: `2px solid ${theme.colors.brand.primary}`,
  color: theme.colors.brand.primary,
  fontSize: 90,
  lineHeight: '1em',
  minWidth: '5.5em',

  ['@media screen and (max-width: 600px)']: {
    fontSize: '15vw',
  },
})

const LogoInner = styled.div({
  height: 0,
  paddingTop: '100%',
  position: 'relative',
})

const LogoText = styled.span({
  fontFamily: 'Kopitha',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  padding: '0 40px 20px',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
})

const LogoTextLine = styled.span({
  display: 'flex',
})
