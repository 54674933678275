import React from 'react'
import styled from '@emotion/styled'

import { theme } from '../theme'

interface ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick
}) => (
  <View
    children={children}
    onClick={onClick}
  />
)

const View = styled.button({
  background: 'transparent',
  border: `1px solid ${theme.colors.brand.primary}`,
  padding: '8px 26px',
  minHeight: 40,
  color: theme.colors.brand.primary,
  borderRadius: 500,
  transition: 'border 0.12s ease-in-out, color 0.12s ease-in-out',
  cursor: 'pointer',

  ['&:hover']: {
    border: `1px solid ${theme.colors.brand.primaryTweak}`,
    color: theme.colors.brand.primaryTweak,
  }
})
