import { IParticlesProps } from 'react-tsparticles'

import starWhiteImage from '../../images/star-white.png'
import starPurpleImage from '../../images/star-purple.png'
import starBlueImage from '../../images/star-blue.png'

const PARTICLE_COUNT = 1500
const IMAGE_SHAPE_SIZE = 148

const imageShapeWhite = {
  src: starWhiteImage,
  width: IMAGE_SHAPE_SIZE,
  height: IMAGE_SHAPE_SIZE,
}

const imageShapePurple = {
  src: starPurpleImage,
  width: IMAGE_SHAPE_SIZE,
  height: IMAGE_SHAPE_SIZE,
}

const imageShapeBlue = {
  src: starBlueImage,
  width: IMAGE_SHAPE_SIZE,
  height: IMAGE_SHAPE_SIZE,
}

export const starsParticlesOptions: IParticlesProps['options'] = {
  detectRetina: true,
  fpsLimit: 60,
  pauseOnOutsideViewport: true,
  fullScreen: {
    enable: true,
    zIndex: -1,
  },
  // interactivity: {
  //   events: {
  //     onHover: {
  //       enable: true,
  //       mode: 'connect',
  //     }
  //   },
  //   modes: {
  //     connect: {
  //       radius: 120,
  //       links: {
  //         opacity: 0.15,
  //       },
  //     },
  //   },
  // },
  particles: {
    opacity: {
      value: 0.9,
    },
    move: {
      enable: true,
      outMode: 'out',
      speed: 1,
      direction: 'bottom-left',
      angle: {
        offset: 0,
        value: 6,
      },
    },
    number: {
      // density: {
      //   enable: true,
      //   area: 800,
      // },
      value: PARTICLE_COUNT,
    },
    shape: {
      type: 'images',
      images: [
        imageShapeWhite,
        imageShapeWhite,
        imageShapeWhite,
        imageShapeWhite,
        imageShapeWhite,
        imageShapeWhite,
        imageShapeWhite,
        imageShapeWhite,
        imageShapePurple,
        imageShapeBlue,
      ]
    },
    size: {
      value: 32,
    },
    zIndex: {
      value: 5,
      opacityRate: 1,
      sizeRate: 0.6,
      velocityRate: 1,
    },
    groups: {
      z70: {
        number: {
          value: PARTICLE_COUNT * 0.5,
        },
        zIndex: {
          value: 70,
        },
        lineLinked: {
          enable: false,
        },
      },
      z50: {
        number: {
          value: PARTICLE_COUNT * 0.25,
        },
        zIndex: {
          value: 50,
        },
        lineLinked: {
          enable: false,
        },
      },
      z30: {
        number: {
          value: PARTICLE_COUNT * 0.15,
        },
        zIndex: {
          value: 30,
        },
        lineLinked: {
          enable: false,
        },
      },
    },
  },
  emitters: {
    autoPlay: true,
    rate: {
      quantity: 1,
      delay: {
        min: 1,
        max: 2.5,
      },
    },
    shape: 'square',
    startCount: 0,
    size: {
      mode: 'percent',
      width: 100,
      height: 100,
    },
    position: {
      x: 50,
      y: 50,
    },
    particles: {
      color: {
        value: 'rgb(255, 255, 255)',
      },
      shape: {
        type: 'images',
        images: [
          imageShapeWhite,
          imageShapeBlue,
          imageShapePurple,
        ]
      },
      size: {
        value: 36,
        animation: {
          enable: true,
          sync: true,
          destroy: 'min',
          startValue: 'min',
          minimumValue: 0.1,
          speed: 200,
        },
      },
      shadow: {
        enable: false,
      },
      move: {
        outMode: 'destroy',
        enable: true,
        speed: 150,
        direction: 'top-right',
        angle: {
          offset: 0,
          value: 10,
        },
        decay: 0.25,
      },
    },
  },
}
