import React from 'react'

import { HomePage } from '../home'

const IndexPage = () => {
  return (
    <HomePage />
  )
}

export default IndexPage
